.container {
  display: grid;
  grid-template-rows: 15vh 20vh 10vh 20vh 10vh 10vh 10vh repeat(30, min-content);
  grid-template-columns: repeat(2, minmax(1rem, 1fr)) repeat(4, minmax(min-content, 0.5fr)) repeat(2, minmax(1rem, 1fr));
}

.header-name{ 
  display: flex;
  grid-column: 3/span 3;
  grid-row: 2;
  color: #595959;
  font-size: 2em;
  font-family: 'Roboto', sans-serif;
  transition: 2s;

}

.header-name:hover {
  color: #37bb3f;
}

.plants-svg {

  background: url("./components/images/plants.svg") no-repeat;
  background-size: auto;
  grid-column: 1/span 7;
  grid-row: 3/span 4;
  background-size: 123% 130%;

}

.social-media {
  display: flex;
  grid-column: -2;
  grid-row: 1;
  font-size: 2em;
}

a {
  color: #d58860;
  transition: 0.5s;

}

a:hover {

  color: #87b272;
}

.nav__link {
margin:0.4em;
}